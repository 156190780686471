








































































































import { StakingViewModel } from "../viewmodels/staking-viewmodel";
import { Vue, Component, Inject, Ref } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {},
})
export default class Staking extends Vue {
  @Inject() vm!: StakingViewModel;
  @Ref("form") form: any;

  validate() {
    return this.form.validate();
  }

  changePremiumTicketInputNo(num) {
    if (this.validate()) {
      this.vm.changePremiumTicketInputNo(num);
    }
  }

  changeStandardTicketInputNo(num) {
    if (this.validate()) {
      this.vm.changeStandardTicketInputNo(num);
    }
  }
}
